// src/components/Contact.js
import React from 'react';

const Contact = () => {
    return (
        <div className="container">
            <h1>Contact Us</h1>
            <p>If you have any questions or concerns, please feel free to contact us using the information below.</p>
            <div className="contact-details">
                <h2>Organization Name</h2>
                <p>Ross Squared, LLC</p>
                <h2>Address</h2>
                <p>44 S Maple Ave, Lansdowne, PA 19050</p>
                <h2>Contact Number</h2>
                <p>(352) 364-3453</p>
                <h2>Email Address</h2>
                <p><a href="mailto:support@amnathrig.com">admin@amnathrig.com</a></p>
            </div>
        </div>
    );
};

export default Contact;
